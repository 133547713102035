<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="仓库:" style="width: 330px">
        <el-select v-model="searcForm.wmsIds" multiple placeholder="">
          <el-option
            v-for="item in storehouse"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="商品:"
        prop="productId"
        style="width: 330px"
        class="F"
      >
        <el-input
          v-if="checkProductName === ''"
          placeholder="请选择商品"
          clearable
          v-model="checkProductName"
          @focus.stop="openProduct"
          @clear="clearProduct"
        ></el-input>
        <el-tooltip
          v-else
          class="item"
          effect="dark"
          :content="checkProductName"
          placement="top-start"
        >
          <el-input
            placeholder="请选择商品"
            clearable
            v-model="checkProductName"
            @focus.stop="openProduct"
            @clear="clearProduct"
          ></el-input>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="批次:" style="width: 330px" class="F">
        <el-input
          v-model="searcForm.batchNo"
          placeholder="请输入批次号"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-form-item label="保质期状态:" style="width: 330px" class="F">
          <el-select v-model="searcForm.expireType" placeholder="">
            <el-option label="正常" value="1"></el-option>
            <el-option label="临期" value="2"></el-option>
            <el-option label="过期" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item label="" style="margin-right: 20px">
        <el-checkbox v-model="searcForm.isZero">是否显示零库存</el-checkbox>
      </el-form-item>
      <div>
        <el-button type="primary" @click="searchForm">查询</el-button>
        <el-button type="primary" @click="handleDownload" plain>导出</el-button>
      </div>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border="">
      <el-table-column prop="code" label="商品编号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="商品名称" align="center">
      </el-table-column>
      <el-table-column prop="barCode" label="条码" align="center">
        <template slot-scope="scope">
          {{ scope.row.barCode ? scope.row.barCode : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="spec" label="规格" align="center">
        <template slot-scope="scope">
          {{ scope.row.spec ? scope.row.spec : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="基础单位" align="center">
      </el-table-column>
      <el-table-column prop="wmsName" label="仓库" align="center">
      </el-table-column>
      <el-table-column prop="batchNo" label="批次号" align="center">
      </el-table-column>
      <el-table-column prop="manufactureDate" label="生产日期" align="center">
      </el-table-column>
      <el-table-column prop="expire" label="保质期天数" align="center">
      </el-table-column>
      <el-table-column prop="expireDate" label="到期日期" align="center">
      </el-table-column>
      <el-table-column prop="remainingDay" label="剩余天数" align="center">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.remainingDay > 0 &&
              scope.row.remainingDay < scope.row.warnNum
            "
            style="color: orange"
            >{{ scope.row.remainingDay }}</span
          >
          <span v-else-if="scope.row.remainingDay <= 0" style="color: red">{{
            scope.row.remainingDay
          }}</span>
          <span v-else>{{ scope.row.remainingDay }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="数量" align="center">
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="changePageSizes"
      @current-change="changeCurrentPages"
      :current-page="searcForm.pageNum"
      :page-size="searcForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isStatement="true"
          :checkArr="checkProductArr"
          @close="Visible = false"
          @SelectGoods="changeProductId"
        />
      </div>
    </miaDialog>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import SelectGoods from "@/components/pustorageTable/SelectGoods.vue";
import { getProductList, getTheWarehouseList } from "@/api/procurementSummary";
import { getBatchCondition } from "@/api/inventoryTable";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    SelectGoods,
    OpenAccount,
  },
  data() {
    return {
      info: {
        show: false,
      },
      storehouse: [], //仓库列表
      productList: [], //商品列表
      tableData: [], //表格数据
      searcForm: {
        productIds: "",
        wmsIds: "",
        pageNum: 1,
        pageSize: 50,
        batchNo: "",
        expireType: "",
        isZero: "",
      }, //搜索条件
      Visible: false,
      checkProductArr: [],
      checkProductName: "",
      total: 0,
    };
  },
  created() {
    this.getConfigBills();
    this.getLists();
    this.getProductList();
    this.getTheWarehouseList();
  },
  methods: {
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
        }
      }
    },
    // 分页
    changePageSizes(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getLists();
    },
    changeCurrentPages(val) {
      this.searcForm.pageNum = val;
      this.getLists();
    },
    clearProduct() {
      this.checkProductArr = [];
    },
    checkProductArrs(val) {
      this.checkProductName =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.name;
              })
              .join("、");
      this.searcForm.productIds =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.id;
              })
              .join(",");
    },
    openProduct() {
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.SelectGoods.init("选择商品");
      });
    },
    changeProductId(list) {
      this.Visible = false;
      this.checkProductArr = list;
      this.checkProductArrs(list);
    },
    async getLists() {
      let params = {
        productIds: this.searcForm.productIds,
        wmsIds: this.searcForm.wmsIds,
        pageNum: this.searcForm.pageNum,
        pageSize: this.searcForm.pageSize,
        batchNo: this.searcForm.batchNo,
        expireType: this.searcForm.expireType,
        isZero: this.searcForm.isZero ? 1 : 0,
      };
      let res = await getBatchCondition(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    //查询按钮
    searchForm() {
      this.getLists();
    },
    //获取仓库列表
    async getTheWarehouseList() {
      let res = await getTheWarehouseList();
      if (res.code == 1) {
        this.storehouse = res.data.records;
      }
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        // const multiHeader = [
        //   [
        //     "供应商",
        //     "商品编号",
        //     "商品名称",
        //     "规格",
        //     "条形码",
        //     "采购入库",
        //     "",
        //     "采购退货",
        //     "",
        //     "合计",
        //     "",
        //   ],
        // ]; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = [
          "商品编号",
          "商品名称",
          "条码",
          "规格",
          "基础单位",
          "仓库",
          "批次号",
          "生产日期",
          "保质期天数",
          "到期日期",
          "剩余天数",
          "数量",
        ]; // 最后一行的表头数据
        const filterVal = [
          "code",
          "name",
          "barCode",
          "spec",
          "unit",
          "wmsName",
          "batchNo",
          "manufactureDate",
          "expire",
          "expireDate",
          "remainingDay",
          "num",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          // multiHeader,
          header,
          // merges,
          data,
          filename: "批次状况表",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>

<style scoped>
.view-wrap {
  position: relative;
}
.pagination {
  width: 100%;
  text-align: center;
  /* margin-top: 10px; */
  position: absolute;
  bottom: -48px;
}
.F {
  display: flex;
  flex-wrap: wrap;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
</style>